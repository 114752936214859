<template>
    <div id="navbar">
		<div v-if="!isMobile" class=" s-header s-flex">
		<div class="s-ml-auto s-main-container">
			<div class="s-position-relative">
				<div class="s-lang-container s-wd-100 s-center-flex s-bg-white" @click.prevent="openDrop =  !openDrop">
					<input readonly v-model="currentLang" class="s-cursor-pointer s-wd-56">
					<div class="s-square-16">
						<img class="s-fullWidth s-cursor-pointer" :src="require('@/assets/icons/primary/icon-arrow-down.svg')"/>
					</div>
				</div>
				<div v-if="openDrop" class="s-position-absolute s-card s-wd-80 s-p-5 s-bg-white" style=" z-index: 100">
					<div class="s-card-item s-cursor-pointer" @click.prevent="changeLang('id')" style="font-size: 12px">ID</div>
					<div class="s-card-item s-cursor-pointer" @click.prevent="changeLang('en')" style="font-size: 12px">EN</div>
				</div>
			</div>
		</div>
		</div>
		<div class="nav s-center-flex s-main-container" :class="onScroll ? 'nav-onscroll' : ''">
		<div :class="`s-mr-10 s-square-${isMobile? '30':'40'}`">
			<img class="s-fullHeigth" :src="require('@/assets/logo/logo_unesco.png')" />
		</div>
		<div class=" s-mr-10" :class="isMobile? 's-square-30':'s-wd-100'">
			<img class="s-fullWidth" :src="require(`@/assets/logo/logo_bkb_borobudur${isMobile? '_single':''}.png`)" />
		</div>
		<div :class="isMobile? 's-square-30':'s-wd-250'">
			<img class="s-fullWidth" :src="require(`@/assets/logo/logo_uajy${isMobile?'_single':''}.png`)"/>
		</div>
		<div class="s-ml-auto"></div>
		<div v-if="!isMobile" class="s-center-flex">
			<div class="nav-item" @click.prevent="pushRoute('Home Public')" :class="navActive == 'Home Public'? 's-text-secondary': '' ">{{$t('navbar.home')}}</div>
			<div class="nav-item" @click.prevent="pushRoute('Content List')" :class="navActive == 'Content List'? 's-text-secondary': '' ">{{$t('navbar.contents')}}</div>
			<div class="nav-item" @click.prevent="pushRoute('Feedback Form')" :class="navActive == 'Feedback Form'? 's-text-secondary': '' ">{{$t('navbar.feedback')}}</div>
			<router-link :to="$translate({name: 'Expert Matching', params: {contentType: 'list'}})" class="s-text-none">
				<div class="nav-item" :class="navActive == 'Expert Matching'? 's-text-secondary': '' ">{{$t('navbar.expertMatching')}}</div>
			</router-link>
			<div class="nav-item-login s-position-relative">
			<div v-if="isLoggedIn"  class="s-account-menu  s-ml-16 s-wd-80-min">
				<div class="s-center-flex s-text-left " @click.prevent="openModal = !openModal">
				<div style="white-space: nowrap;  overflow: hidden; text-overflow: ellipsis;" class="s-wd-150-max">
					Hi, {{user.name}} !
				</div>
				<div class="s-square-30">
					<img :src="require('@/assets/icons/primary/icon-chevron-down.svg')">
				</div>
				</div>
				<div v-if="openModal" class="s-card s-p-5 s-text-center  s-bg-white s-position-absolute">
				<router-link class="s-text-none" :to="$translate({name: 'Dashboard CMS'})">
					<div class="s-card-item s-text-none">
					<p>Dashboard</p>
					</div>
				</router-link>
				<div @click.prevent="logout()" class="s-card-item">
					<p>Logout</p>
				</div>
				</div>
			</div>
			<router-link v-else :to="$translate({name:'authentication', params:{authType: 'login'}})" class="s-ml-auto">
				<Button padding="10">{{$t('login.login')}}</Button>
			</router-link>
			</div>
		</div>
		<div v-else class="s-position-relative">
			<div class="s-center-flex">
				<div class="s-menu-burger s-ml-auto" @click.prevent="setCollapse()">
					<img :src="require('@/assets/icons/primary/icon-menu.svg')">
				</div>
			</div>
		</div>
		</div>
		<div v-if="collapse && isMobile" class="s-menu-sidebar">
		<div class="s-menu-header">
			<router-link v-if="isLoggedIn" :to="$translate({name:'authentication', params:{authType: 'login'}})">
				<div class="s-ptb-5 s-plr-16 r-primary-filled-btn s-radius-20" style="font-size: 12px">Login</div>
			</router-link>
			<div >
				Hi, {{user.name}} !
			</div>
			<div class="s-ml-10">
				<div class="s-position-relative">
				<div class="s-lang-container s-wd-100 s-center-flex" @click.prevent="openDrop =  !openDrop">
					<input readonly v-model="currentLang" class="s-cursor-pointer s-wd-56">
					<div class="s-square-16">
						<img class="s-fullWidth s-cursor-pointer" :src="require('@/assets/icons/primary/icon-arrow-down.svg')"/>
					</div>
				</div>
				<div v-if="openDrop" class="s-position-absolute s-card s-wd-80 s-p-5 s-bg-white" style=" z-index: 100">
					<div class="s-card-item s-cursor-pointer" @click.prevent="changeLang('id')" style="font-size: 12px">ID</div>
					<div class="s-card-item s-cursor-pointer" @click.prevent="changeLang('en')" style="font-size: 12px">EN</div>
				</div>
				</div>
			</div>
			<div class="s-ml-auto" @click.prevent="setCollapse()">
				<img class="s-cursor-pointer" :src="require('@/assets/icons/close-primary.svg')">
			</div>
		</div>
		<div class="s-menu-body">
			<div class="s-menu-item" @click.prevent="pushRoute('Home Public'), setCollapse()">{{$t('navbar.home')}}</div>
			<div class="s-menu-item" @click.prevent="pushRoute('Content List'), setCollapse()">{{$t('navbar.contents')}}</div>
			<div class="s-menu-item" @click.prevent="pushRoute('Feedback Form'), setCollapse()">{{$t('navbar.feedback')}}</div>
			<div class="s-menu-item" @click.prevent="pushRoute('Content List'), setCollapse()">{{$t('navbar.expertMatching')}}</div>
			<div class="s-menu-item" @click.prevent="pushRoute('Dashboard CMS'), setCollapse()">Dashboard</div>
			<div class="s-menu-item s-text-error" @click.prevent="logout(), setCollapse()">Logout</div>
		</div>
	</div>
    </div>
	
</template>
<script>
import Button from '@/components/helper/Button';
import { mapActions, mapGetters } from 'vuex';
import { Trans } from '@/plugins/translation'
export default {
  components: {
    Button
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
	this.setCollapse()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  data: () => ({
    onScroll: false,
    openModal: false,
    openDrop: false,
  }),
  computed: {
    ...mapGetters({
		user: 'auth/getUser',
		isLoggedIn: 'getIsLoggedIn',
		lang: 'locale/getCurrentLocale',
		isMobile: 'getIsMobile',
		collapse: 'getCollapse'
    }),
    navActive() {
		return this.$route.name;
    },
	currentLang(){
		if(this.lang == 'id') return'Indonesia'
		else return 'English'
	}
	},
  methods: {
    ...mapActions({
      logoutStorage: 'logoutStorage',
      logoutUser: 'auth/logoutUser',
      setLocale: 'locale/setCurrentLocale'
    }),
    handleScroll() {
      if (window.scrollY > 50) this.onScroll = true;
      else this.onScroll = false;
    },
	setCollapse() {
      this.$store.commit("SET_COLLAPSE", !this.collapse);
    },
    async switchLocale(){
          await Trans.changeLocale(this.lang)
          let route = {
              ...this.$route,
          }
          route.params = {
              ...route.params,
              locale: this.lang == 'en' ? 'en' : null
          }
          this.$router.push(route).catch(error => {
				if (error.name != "NavigationDuplicated") {
					throw error;
				}
			})
    },
    pushRoute(name) {
      this.$router.push(this.$translate({ name: name }));
    },
    changeLang(lang){
        this.setLocale(lang)
        this.openDrop =  false
        this.switchLocale()
    },
    async logout() {
		await this.logoutUser();
		await this.logoutStorage();
		location.reload();
    },
  },
};
</script>
